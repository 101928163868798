import { template as template_1ae33048edb54d1796a10a3a44cee2e8 } from "@ember/template-compiler";
import Component from "@glimmer/component";
import { service } from "@ember/service";
import CategoriesSection from "./categories-section";
import CustomSections from "./custom-sections";
import TagsSection from "./tags-section";
export default class SidebarAnonymousSections extends Component {
    @service
    siteSettings;
    static{
        template_1ae33048edb54d1796a10a3a44cee2e8(`
    <div class="sidebar-sections sidebar-sections-anonymous">
      <CustomSections @collapsable={{@collapsableSections}} />
      <CategoriesSection @collapsable={{@collapsableSections}} />

      {{#if this.siteSettings.tagging_enabled}}
        <TagsSection @collapsable={{@collapsableSections}} />
      {{/if}}
    </div>
  `, {
            component: this,
            eval () {
                return eval(arguments[0]);
            }
        });
    }
}
