import { template as template_f514db7b2d6a44a0bfb554a841e2927c } from "@ember/template-compiler";
const FKText = template_f514db7b2d6a44a0bfb554a841e2927c(`
  <p class="form-kit-text" ...attributes>
    {{yield}}
  </p>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKText;
